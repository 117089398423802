var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-card",
        {
          staticClass:
            "d-flex flex-column align-items-start grey darken-4 rounded-xl pa-4",
          attrs: { flat: "", dark: "" },
        },
        [
          _c("span", { staticClass: "d-block f15 text-center mb-4" }, [
            _vm._v(_vm._s(_vm.exercise.title)),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex white rounded-xl w-100 px-1 align-items-start",
            },
            [
              _c("v-textarea", {
                staticClass: "rounded-xl",
                attrs: {
                  solo: "",
                  flat: "",
                  "single-line": "",
                  light: "",
                  type: "text",
                  placeholder: _vm.$t("Write..."),
                  rules: [(v) => !!v],
                  required: "",
                  "auto-grow": "",
                  counter: "256",
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _vm.exerciseProp.exerciseTypeId == "13"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            fab: "",
                            elevation: "0",
                            color: "primary",
                            small: "",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.add()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v(" mdi-plus "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.exerciseProp.exerciseTypeId == "16"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            fab: "",
                            elevation: "0",
                            color: "green",
                            small: "",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.like()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v(" mdi-thumb-up "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.exerciseProp.exerciseTypeId == "16"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            fab: "",
                            elevation: "0",
                            color: "red",
                            small: "",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dislike()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v(" mdi-thumb-down "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._l(_vm.asnwers, function (item, i) {
        return _c(
          "v-card",
          {
            key: i,
            staticClass: "rounded-xl my-2 border",
            attrs: { flat: "" },
          },
          [
            _vm.exerciseProp.exerciseTypeId == "16" && item.strength
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center py-2 ps-4 pe-2 light-green lighten-5",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-block f15 fw-bold green--text break-words ms-0 me-1",
                      },
                      [_vm._v(_vm._s(item.content))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ms-auto me-0",
                        attrs: {
                          fab: "",
                          outlined: "",
                          elevation: "0",
                          color: "red",
                          "x-small": "",
                          dark: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAnswer(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { color: "red" } }, [
                          _vm._v(" mdi-trash-can-outline "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.exerciseProp.exerciseTypeId == "16" && !item.strength
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center py-2 ps-4 pe-2 red lighten-5",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-block f15 fw-bold red--text break-words ms-0 me-1",
                      },
                      [_vm._v(_vm._s(item.content))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ms-auto me-0",
                        attrs: {
                          fab: "",
                          outlined: "",
                          elevation: "0",
                          color: "red",
                          "x-small": "",
                          dark: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAnswer(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { color: "red" } }, [
                          _vm._v(" mdi-trash-can-outline "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center py-2 ps-4 pe-2",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-block f15 fw-bold dark--text break-words ms-0 me-1",
                      },
                      [_vm._v(_vm._s(item.content))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ms-auto me-0",
                        attrs: {
                          fab: "",
                          outlined: "",
                          elevation: "0",
                          color: "red",
                          "x-small": "",
                          dark: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAnswer(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { color: "red" } }, [
                          _vm._v(" mdi-trash-can-outline "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }